// Generated with `rails g currency_map_service` in gaming_api. DO NOT EDIT!

export const CASINO_CURRENCIES: string[] = [
  'ADA',
  'AED',
  'ALG',
  'ARS',
  'ATM',
  'AU1',
  'AU2',
  'AUD',
  'AVA',
  'AVE',
  'BCH',
  'BE1',
  'BNB',
  'BNK',
  'BRL',
  'BRT',
  'BSV',
  'BTC',
  'CAD',
  'CAK',
  'CHF',
  'CNY',
  'CRO',
  'CZK',
  'DEG',
  'DGS',
  'DKK',
  'DOG',
  'DOT',
  'DSH',
  'ENA',
  'EOS',
  'ETC',
  'ETH',
  'EUR',
  'FTM',
  'FTT',
  'GBP',
  'HBA',
  'HKD',
  'HMS',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'JSS',
  'KAR',
  'KRW',
  'LI1',
  'LN2',
  'LNA',
  'LTC',
  'MAT',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PE1',
  'PHP',
  'PNK',
  'PTS',
  'RC1',
  'RSD',
  'SDE',
  'SEK',
  'SHI',
  'SOL',
  'STE',
  'STM',
  'STS',
  'SUS',
  'THB',
  'TMP',
  'TON',
  'TOS',
  'TRX',
  'TRY',
  'UNI',
  'US2',
  'US3',
  'US4',
  'US5',
  'US6',
  'US7',
  'US8',
  'US9',
  'USD',
  'USE',
  'VND',
  'XLM',
  'XRP',
  'XTZ',
  'ZEC',
];

export const HUMANIZED_CURRENCIES: { [key: string]: string } = {
  ADA: 'ADA',
  AED: 'AED',
  ALG: 'ALGO',
  ARS: 'ARS',
  ATM: 'ATOM',
  AU1: 'PAXG',
  AU2: 'XAUT',
  AUD: 'AUD',
  AVA: 'AVAX',
  AVE: 'AAVE',
  BCH: 'BCH',
  BE1: 'BONUS_EUR',
  BNB: 'BNB',
  BNK: 'BONK',
  BRL: 'BRL',
  BRT: 'BRETT',
  BSV: 'BSV',
  BTC: 'BTC',
  CAD: 'CAD',
  CAK: 'CAKE',
  CHF: 'CHF',
  CNY: 'CNY',
  CRO: 'CRO',
  CZK: 'CZK',
  DEG: 'DEGEN',
  DGS: 'DOGS',
  DKK: 'DKK',
  DOG: 'DOGE',
  DOT: 'DOT',
  DSH: 'DASH',
  ENA: 'ENA',
  EOS: 'EOS',
  ETC: 'ETC',
  ETH: 'ETH',
  EUR: 'EUR',
  FTM: 'FTM',
  FTT: 'FTT',
  GBP: 'GBP',
  HBA: 'HBAR',
  HKD: 'HKD',
  HMS: 'HMSTR',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JPY: 'JPY',
  JSS: 'JITOSOL',
  KAR: 'KARATE',
  KRW: 'KRW',
  LI1: 'LINK',
  LN2: 'LUNA',
  LNA: 'LUNC',
  LTC: 'LTC',
  MAT: 'MATIC',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PE1: 'PLAY_EUR',
  PHP: 'PHP',
  PNK: 'PONKE',
  PTS: 'PTS',
  RC1: 'REWARD_CASH',
  RSD: 'RSD',
  SDE: 'SUSDE',
  SEK: 'SEK',
  SHI: 'SHIB',
  SOL: 'SOL',
  STE: 'LIDO_STAKED_ETHER',
  STM: 'LIDO_STAKED_MATIC',
  STS: 'LIDO_STAKED_SOLANA',
  SUS: 'SUSHI',
  THB: 'THB',
  TMP: 'TRUMP',
  TON: 'TON',
  TOS: 'TOSHI',
  TRX: 'TRON',
  TRY: 'TRY',
  UNI: 'UNI',
  US2: 'USDT',
  US3: 'USDC',
  US4: 'TUSD',
  US5: 'USDP',
  US6: 'GUSD',
  US7: 'DAI',
  US8: 'USTC',
  US9: 'BUSD',
  USD: 'USD',
  USE: 'USDE',
  VND: 'VND',
  XLM: 'XLM',
  XRP: 'XRP',
  XTZ: 'XTZ',
  ZEC: 'ZCASH',
};
